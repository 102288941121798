/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
export { wrapRootElement } from "./src/apollo/wrap-root-element";

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    if (location.pathname.match(/job-search/)) {
        return false;
    }

    return true;
};

// export const shouldUpdateScroll = ({
//     routerProps: { location },
//     getSavedScrollPosition
// }) => {
  
//     // Avoid to scroll to top when next page trigger click
//     // But this feature should condiser with details page back scroll too
//     if (location.pathname.match(/jobs/)) {
//       return false;
//     }  
//     return true;
// };